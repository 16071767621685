.filter_widget select{

    padding: 8px 20px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    border-radius: 5px;
    background: var(--primary-color);
    color: #fff;
}
.filter_widget select:focus{
    outline:none !important
}

.filter_widget select option{

    font-size: .9rem;
}

.search_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 3px;


}

.search_box input{
    width:100%;
    border: none;
    outline: none;
    padding: 8px 10px;
}
.search_box span{
    color: var(--primary-color);
}

@media only screen and (max-width:768px) {
    .filter_widget{
        padding-bottom: 30px;
    }
    .filter_widget select{

        padding: 7px 20px;
        font-size: 0.9rem;
    }
}