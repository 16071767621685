
@media only screen and (max-width:768px){

    .clock_wrapper{
        justify-content: center;
        font-size: 1.1rem;
        
    }
  .clock_data h1{
    font-size: 1.1rem !important;
  }
  .clock_data h5{
    font-size: 0.9rem !important;
  }
}