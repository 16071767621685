.footer{

    padding-top: 60px;
    padding-bottom: 30px;
    background: var(--primary-color);
}
.footer_text{
    color: #a5a0a0;
    line-height: 30px;
}
.footer_quick_links ul li{
background: transparent !important;
}

.footer_quick_links ul li a{
    color:#a5a0a0 ;
}
.quick_links_tittle{

    margin-top: 20px;
    color: #fff;
    font-size: 1.1rem;

}
 .footer_contact li span,
 .footer_conatct li p{
    color:#a5a0a0 ;
 }
 .footer_contact li span{
    font-size: 1.2rem;
 }
 .footer_contact li p{
    font-size: 0.9rem;
 }

 .footer_copyright{
    color: #a5a0a0;
    font-size: 0.9rem;
    margin-top: 50px;
    text-align: center;
 }

