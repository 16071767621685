.table tr td img{
    width:80px;
    height:80px;
    object-fit: cover;
}

td i {
    cursor: pointer;
}
td, th{

    color: var(--primary-color);
}