.product_item{
    cursor: pointer;
  
}
.product_item img{
    width: 180px;
    height: 180px;
  
}

.product_info h3 a{
font-size: 1.2rem;
color: var(--primary-color);
font-weight: 600;
margin-top: 15px;

}
.product_info a:hover{

    color: inherit;
}
.product_info span{
    font-size: 0.9rem;
}
.product_card_bottom .price{
    color: var(--primary-color);
    font-size: 1.3rem;
    font-weight: 500;
}
.product_card_bottom span i{

    font-size: 1.2rem;
    padding: 5px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 50px;
}

@media only screen and (max-width:992px){
  
    .product_info h3 a{
        font-size: 1.1rem;
    }

} 
@media only screen and (max-width:992px){
  
    .product_info h3 a{
        font-size: 1rem;
    }

    .product_card_bottom span i {
        font-size: 1rem;
    }

} 
@media only screen and (max-width:992px){

    .product_img {
       align-content: center;
    }
}