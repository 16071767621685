.best_sales_row{
   
}

.section_title{
    margin-bottom: 2rem;
}

.hero_section{
    background: var(--hero-bg);
}
.hero_content h2{
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 20px 0px;
}
.hero_content p{
    color: var(--primary-color);
    line-height: 28px;
    font-weight: 500;
}
.hero_section p:nth-child(1){
    font-weight: 500;
}
.buy_btn{
    border: none;
    outline: none;
    padding:  8px 20px;
    border-radius: 5px;
    background: var(--primary-color);
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 40px;
}

.buy_btn:hover{
    color: #fff;
}
.hero_content{
    padding-top: 45px;
}

.timer_count{
    background-color: var(--primary-color);
    height: 300px;
}

.timer_count img{
    width: 70% !important;
    height: 70% !important;
    object-fit: contain;
}

.store_btn{
    background: #fff;
    color: var(--primary-color);
    font-weight: 600;
}
.store_btn a:hover{
    color: var(--primary-color);
}
.store_btn:hover{
    color: var(--primary-color);
}

@media only screen and (max-width:992px){
      .hero_content h2{
        font-size: 2rem;
      }  
      .section_title{
        font-size: 1.7rem;
      }
      .counter_img{
        display: none;
      }
      .clock_top_content{
        text-align: center;
      }
      .clock_top_content h4{
        font-size: 0.9rem !important;
      } 
      .clock_top_content h3{
        font-size: 1rem !important;
      }
      .count_down_col{
        text-align:center;
      }

}

@media only screen and (max-width:768px){
    .hero_content h2{
      font-size: 1.6rem;
    }  
    .hero_content p{
            font-size: 0.9rem;
    }
    .buy_btn{
        font-size: 0.9rem;
    }

    .section_title{
        font-size: 1.4rem;
    }

}
@media only screen and (max-width:576px){
    .hero_content {
     padding: 0;
     margin-top: 40px;
    }  

}


