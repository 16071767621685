

.common_selection{

    background: linear-gradient(rgba(0,0,0,0.562), rgba(0, 0, 0, 0.562)),
    url(../assets/images/11.png) no-repeat center center;
    background-size: contain;
    padding: 100px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.common_selection h1{
    color: rgb(255, 255, 255);
    font-weight: 600;
}

@media only screen and (max-width:768px) {


    .common_selection{
        padding: 70px 0px;
    }

    .common_selection h1 {
        font-size: 1.4rem;
    }
}


