header{
    width: 100%;
    height: 70px;
    line-height: 70px;
}

.nav_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
display: flex;
align-items: center;
column-gap: 8px;

}

.logo img{
    width: 1.5rem;
    height: 1.5rem;
}
.logo h1{
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-color);
}

.menu{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    margin-bottom: 0;
    font-size: 1rem;
}

.nav_item a{
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-color);
}
.nav_icons{
    display: flex;
    align-items: center;
    column-gap: 1.7rem;
}
.nav_icons span i{
    font-size:1.4rem;
    color: var(--primary-color);
    cursor: pointer;

}
.cart_icon, .fav_icon{
    position: relative;
}

.badge{
    position: absolute;
    top: 25%;
    right: -8%;
    width: 15px;
    height: 15px;
    content: '';
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50px;
    font-size: 0.7rem;
    font-weight: 600;
    z-index: 10;

}

.nav_icons span img{
    width:30px;
    height:30px;
    cursor: pointer;
    
}
.mobile_menu{
    font-size:1.3rem;
    color: var(--primary-color);
    display: none;
}

.nav_active{
    font-weight: 700 !important;
}
 
.sticky_header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 3px 3px 8px -3px #ddd;

}
@media only screen and (max-width:768px){
.navigation{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.53);
    z-index: 9999;
    display: none;
}

.menu{
    position: absolute;
    top:0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    z-index: 99999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.active_menu{
    display:block;
}
.mobile_menu {
    display: block;
}
.mobile_menu span i{
    font-size: 1.1rem !important;
}
    .logo img{
        width: 1rem;
        height:1rem;
    }
    .logo h1{
        font-size: 1rem;
    }
    .fav_icon i, .cart_icon i{
        font-size: 1.2rem !important;
    }
    .nav_icons span img{
        width: 1.4rem;
        height: 1.4rem;
    }
    .header{
        width: 100%;
        height: 60px;
        line-height: 60px;
    }

}